
export const newsData = [
    {
        id: 'fuchs-foxgear',
        title: "FUCHS – FOXGEAR",
        date: "01.11.2024",
        description: "Российское отделение международного концерна FUCHS SE обретает свой собственный бренд – FOXGEAR ",
        fullText: [
          
            "Продуктовый портфель FOXGEAR будет по-прежнему содержать более 400 наименований, каждое из которых обретет новое имя, сохранив при этом проверенные временем производственные формулы, соответствующие самым высоким мировым стандартам.",
            "ООО «ФУКС ОЙЛ» продолжит обеспечивать надежную и бесперебойную работу по всем направлениям:", 
            " - транспортные и промышленные предприятия", " - сервисные компании", " - индивидуальные потребители.",
             "Переходный период запланирован на 6 месяцев и завершится к маю 2025 года. С начала 2025 года начнется поэтапный выпуск продукции под новым торговым наименованием FOXGEAR. Мы будем своевременно информировать Вас о выходе продуктовых линеек под новым брендом"
        ],
        image: require('../../assetsImage/assets/banner_12x4_01_nosite.jpg'),
    }, 




    
];