import React, {useEffect, useState, useRef } from 'react';
import '../../css/news_1.css';
import SvgHome from '../../assetsImage/assets/house.svg';
import { Link } from 'react-router-dom';
import titlePhoto from '../../assetsImage/assets_solutions/header.jpg'




const HimGornodobivaushaya = () => {

    return (
        <main>
            <div className="product-section-with-images">
                <div className="svg-container">
                <a href=""><Link to="/fuchs-oil"><img src={SvgHome} alt=""/></Link></a>
                </div>
                <div className="svg-container1">
                    <svg viewBox="0 0 24 24" x="0" y="0" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <clipPath id="clip2_203_98" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" fill="white" transform="translate(-0.29290000000000305, 0)" />
                            </clipPath>
                        </defs>
                        <g id="::before" data-node-id="203:107" xmlns="http://www.w3.org/2000/svg">
                            <g id="next.svg fill" data-node-id="203:108" clip-path="url(#clip2_203_98)">
                            <g id="next.svg" data-node-id="203:109">
                                <path id="Vector_2" data-node-id="203:110" d="M9.7071,7l5,5l-5,5" stroke="#90979C" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" />
                            </g>
                            </g>
                        </g>
                        </svg>            
                </div>
                <p className="product-title-text-style-navigation"><a href=""><Link to="/solution">Решения по отраслям</Link></a></p>
                <div className="svg-container1">
                    <svg viewBox="0 0 24 24" x="0" y="0" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <clipPath id="clip2_203_98" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" fill="white" transform="translate(-0.29290000000000305, 0)" />
                            </clipPath>
                        </defs>
                        <g id="::before" data-node-id="203:107" xmlns="http://www.w3.org/2000/svg">
                            <g id="next.svg fill" data-node-id="203:108" clip-path="url(#clip2_203_98)">
                            <g id="next.svg" data-node-id="203:109">
                                <path id="Vector_2" data-node-id="203:110" d="M9.7071,7l5,5l-5,5" stroke="#90979C" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" />
                            </g>
                            </g>
                        </g>
                        </svg>            
                </div>
                <p className="product-title-text-style-navigation"><a href=""><Link to='/solution/khimicheskaya_promyshlennost'>Химическая и горнодобывающая промышленность</Link></a></p>
            </div>
            <div className='news_blocks'>
                <h2>
                  Химическая и горнодобывающая промышленность
                </h2>
                <div className='news_block'>
                    <img src={titlePhoto} alt="Химическая и горнодобывающая промышленность"/>
                    <p>
                         Безопасность компонентов и управление рисками являются одной из главных проблем производителей. Поэтому, группа компаний <b>FUCHS</b> разработала полный спектр масел и смазывающих материалов, отвечающих самым строгим гигиеническим требованиям безопасности на рынке.                   
                     </p>
                    <p>
                      <b>FUCHS</b>, как крупнейший в мире независимый производитель смазочных материалов и специалист по системным решениям предлагает полную линейку масел для подразделений химических предприятий, а также спектр совместимых смазочных материалов. Результатом такого подхода является накопление богатого ноу-хау и индивидуальных решений во всех областях, применение которых дает отличный результат.
                     </p>
                    <p>
                         Компания <b>FUCHS</b> не только заботится о постоянном улучшении технологических свойств своих продуктов. Стремление идти в ногу со временем, производя высококачественные смазочные материалы, ставит перед нами задачи, требующие наукоемких инновационных решений. Принимая на себя ответственность за ту сферу деятельности, в которой мы участвуем, мы делаем все возможное для обеспечения безопасности наших потребителей. Именно безопасность является для нас приоритетом, при внесении тех или иных изменений в состав продуктов.
                     </p>
                    <p>
                          Мы расцениваем производственные достижения наших заказчиков как наши собственные успехи. Только благодаря партнерству мы можем обмениваться опытом. Компания <b>FUCHS</b> обладает целым рядом преимуществ, которые могут быть полезны для наших заказчиков.
                    </p>
                    <p>
                        Во-первых, это преимущество лидера рынка. Концерн <b>FUCHS</b> является крупнейшим в мире независимым в мире производителем смазочных материалов.                    
                    </p>
                    <p>
                         Во-вторых, высокотехнологичная продукция. являясь ведущим поставщиком высококачественных смазочных материалов - концерн задает технологический уровень смазочных материалов.
                    </p>
                    <p>
                       В третьих, это полный ассортимент. Программа производства широкого спектра продукции и возможность разработки индивидуальных технических решений позволяют группе <b>FUCHS</b> удовлетворять все рыночные запросы.
                    </p>
                    <p>
                         В-четвертых, это надежность. В течение десятилетий компания уделяла первостепенное внимание постоянному повышению качества своих специализированных материалов.
                    </p>
                    <p>
                      Как показывает российский опыт, примерно три из четырех предприятий небольшой производственной мощности, приступая к освоению месторождений, приобретают не новую, а бывшую в употреблении технику. По мере развития производства и получения определенного объема товарного металла, предприятия начинают модернизацию производства, приобретая новые машины. При эксплуатации оборудования необходимо учитывать, что добыча полезных ископаемых связана с эксплуатацией техники в тяжелых рабочих условиях. Высокие нагрузки, запыленность, погодные условия и коррозия отрицательно влияют на работу механизмов, что приводит к их быстрому износу
                     </p>
                    <p>
                        Именно поэтому в данной отрасли нужно уделять особое внимание качественному уходу за комплектующими и оборудованием в целом, для повышения износоустойчивости дорогостоящей техники.
                        Специалисты компании <b>FUCHS</b> постоянно работают в тесном сотрудничестве с ведущими предприятиями производителями горнодобывающей техники. Постоянное увеличение нагрузки на оборудование, работа оборудования в условиях запыленности и повышенной влажности требует применения смазочных материалов с повышенными требованиями. Смазочные материалы <b>FUCHS</b> разработаны с учетом все факто¬ров добывающего производства, поэтому их использование рекомендовано во многих случаях.
                    </p>
                    <p>
                          Выпуск продукции на заводе в России позволяет в отличие от конкурентов, указывать и фиксировать стоимость продукции в рублях, учитывая сложные кризисные ситуации.
                    </p>
                    <p>
                         В процессе опытного отбора были выявлены наиболее оптимальные смазочные материалы, характеристики которых полностью отвечают всем
                        требованиям оборудования используемого в горнодобывающей технике.                    
                    </p>

                </div>
            </div>
        </main>
    )
}

export default HimGornodobivaushaya;